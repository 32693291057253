<template>
  <div class="py-3 d-flex justify-content-center"
       style="background-color: #f2f2f2;    border-bottom: #428bca 10px solid;">
    <div class="w1180 bg-white p-4 my-4">
      <NewsHeader :c_title="info.title" :c_date="info.date"/>

      <div class="x12 text-big">
        <p><img loading="lazy" class="wp-image-23629 aligncenter" src="@/assets/news/23/1.png" alt="图片" width="607" height="288"  sizes="(max-width: 607px) 100vw, 607px"></p>
        <p style="text-align: center;">（图片来源于https://travel.state.gov/content/travel/en/legal/visa-law0/visa-bulletin.html)</p>
        <p>美国国务院签证办公室（NVC）公布了2022年5月份的签证公告牌。EB-5新政后的第一份排期表在广大投资人的期待中终于露面了，我们来看一下新政给排期带来了哪些变化。</p>
        <p><strong>职业移民绿卡排期 – 最终裁定日（Action Date / 表A）</strong></p>
        <p><img loading="lazy" class="wp-image-23630 aligncenter" src="@/assets/news/23/2.png" alt="图片" width="693" height="521"  sizes="(max-width: 693px) 100vw, 693px"></p>
        <p>首先，中国大陆出生的EB-5申请人特别关心的表A：</p>
        <p>1. <span style="color: #0000ff;">EB-5投资移民直投项目仍无排期，但公告中提示6月可能出现排期。</span></p>
        <p>2. <span style="color: #0000ff;">EB-5区域中心计划，中国大陆出生的申请人的排期日为2015年11月22日</span>，优先日在此之前的投资人，签证中心会开始安排面试，移民局也会开始处理I-485的申请；</p>
        <p>3. 由于新政为三类项目（<span style="color: #0000ff;">乡村，高失业地区，基建</span>）预留了签证配额，他们成为排期表中新的成员，对于<span style="color: #0000ff;">这三类预留签证类别，目前是无排期状态</span>。</p>
        <p>小编解读：</p>
        <p>旧法案的投资人都非常关心的是，这三类预留的签证是否适用于2022年3月15日之前递交的申请？如果适用的话，如何将旧例申请人分类标记？目前国务院和移民局尚未给出相关的指引。同时，签证中心无法判断排队中的签证申请人是属于哪一类别，所以新三类暂时是无排期状态，预计国务院和移民局近期可能会发布相关的实施细则。</p>
        <p>职业移民绿卡排期 – 递件日期（Filing Date / 表B）</p>
        <p><img loading="lazy" class=" wp-image-23631 aligncenter" src="@/assets/news/23/3.png" alt="图片" width="703" height="524"  sizes="(max-width: 703px) 100vw, 703px"></p>
        <p>在5月签证排期表B中，EB-5直投项目还是无排期状态，区域中心项目排期日为2015年12月15日，与法案暂停前一致，I-526已经获批且优先日在此之前的投资人可以开始准备签证资料，对于在美国的投资人目前还需等待移民局公布5月是否可以使用表B递交I-485申请。如果移民局5月开放使用表B，可以尽快递交I-485申请，并同时递交Combo卡（工卡/返美证）申请，提前在美国享受绿卡的待遇。跃迁将继续密切关注相关动态，并为申请人提供准确的资讯。</p>
        <p>在签证中心即将于5月恢复签证面试之际，移民局也官宣了即日起恢复I-526申请的处理，受疫情停摆和法案暂停双重影响，几乎停滞了2年的<span style="color: #0000ff;">I-526申请审批流程终于重启了</span>。</p>
        <p><img loading="lazy" class="wp-image-23632 aligncenter" src="@/assets/news/23/4.png" alt="图片" width="658" height="348"  sizes="(max-width: 658px) 100vw, 658px"></p>
        <p>对于其他类别的就业移民申请人，本月排期基本没有变化：</p>
        <p>其中表A：</p>
        <p>1. EB-1类别显示“C”，无排期</p>
        <p>2. EB-2类别排期为2019年3月1日，原地踏步</p>
        <p>3. EB-3类别排期为2018年3月22日，原地踏步</p>
        <p>4. EB-4类别显示“C”，无排期</p>
        <p>表B 中各类项目的排期都没有变化。</p>
        <p>随着 “2022 EB-5改革和诚信法案”于3月16日正式生效，历经7年，围绕涨价和签证分配的争论和博弈终于尘埃落定，接下来移民局会发布新法的实施细则和指南，广大投资人关心的签证分配，高失业地区认定，排期等问题也会有更清晰的解读。</p>
      </div>

      <NewsFooter class="mt-5" :next="next" :prev="prev"/>
    </div>
  </div>

</template>

<script>
import NewsHeader from "@/components/News/NewsHeader";
import NewsFooter from "@/components/News/NewsFooter";
import news from "@/news";

export default {
  name: "news23",
  components: {NewsHeader, NewsFooter},
  data() {
    return {
      id:23,
      info: {},
      next: null,
      prev: null
    }
  }, mounted() {
    let id = this.id
    this.info = news.find(item => item.id === id)
    this.next = news.find(item => item.id === (id + 1))
    this.prev = news.find(item => item.id === (id - 1))
  }
}
</script>

<style scoped>
.aligncenter {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

a {
  color: #428bca;
  text-decoration: none;
}
</style>